import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

// eslint-disable-next-line react/prop-types
const AmazonPay = ({ premiumPlan, isLoggedIn }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const getPlanDetails = plan => {
    switch (plan) {
      case 1:
        return { duration: '1 month', amount: '₹ 350' }
      case 2:
        return { duration: '2 months', amount: '₹ 600' }
      case 3:
        return { duration: '3 months', amount: '₹ 900' }
      default:
        return { duration: '', amount: '' }
    }
  }

  const { duration, amount } = getPlanDetails(premiumPlan)

  return (
    <div className="text-center">
      {isLoggedIn ? (
        <Button color="warning" onClick={toggle} className="mt-3">
          <i className="fab fa-amazon mr-2" aria-hidden="true"></i>
          Amazon Pay eGift Card
          <strong className="ml-2">{amount}</strong>
        </Button>
      ) : (
        <></>
      )}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Upgrade using Amazon Pay eGift Card
        </ModalHeader>
        <ModalBody>
          {duration && amount && (
            <>
              <p className="font-weight-bold">
                <i className="fab fa-amazon mr-2" aria-hidden="true"></i>Buy an
                Amazon Pay eGift Pay Card:{' '}
                <strong className="text-danger">{amount}</strong>
              </p>
            </>
          )}
          <p>
            Send the Amazon Pay eGift Card details/Share Link to{' '}
            <strong className="text-danger">
              teamcboxera@gmail.com
              <Button
                color="link"
                onClick={() => {
                  navigator.clipboard.writeText('teamcboxera@gmail.com')
                  alert('Email copied')
                }}
                size="sm"
              >
                <i className="fas fa-copy" aria-hidden="true"></i>
              </Button>
            </strong>
          </p>
          <p className="mt-4">
            <i className="fas fa-info-circle mr-2" />
            Please include your <strong>username</strong> and{' '}
            <strong>email</strong>. Once we validate your gift card we will
            upgrade your account asap.
          </p>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AmazonPay
